<template>
  <tr class="grid text-left sm:table-row">
    <td class="text-left sm:w-2/12 sm:h-8 lg:w-1/12 lg:h-10">#{{ String(index + 1).padStart(3, '0') }}</td>
    <td v-if="!isDashboard" class="sm:w-4/12 sm:h-8 lg:w-3/12 lg:h-10">
      <RouterLink
        class="lg:hover:text-orange-500 text-black focus:outline-none transition-colors duration-150 ease-in-out"
        :to="`/${item.username}`"
      >
        <template v-if="item.first_name && item.last_name"> {{ item.first_name }} {{ item.last_name }} </template>
        <template v-else>{{ item.username }}</template>
      </RouterLink>
    </td>
    <td class="font-bold sm:w-2/12 sm:h-8 lg:h-10">
      <button
        class="
          lg:hover:text-orange-500
          text-black
          font-bold
          focus:outline-none
          transition-colors
          duration-150
          ease-in-out
        "
        @click.prevent="showVideo()"
      >
        <AtomTime :duration="item.laptime"></AtomTime>
      </button>
    </td>
    <td v-if="isDashboard && (item.slug || item.date)" class="hidden lg:table-cell lg:h-10 lg:h-8 lg:text-right">
      <RouterLink
        v-if="item.slug"
        :to="`/event/${item.slug}`"
        class="lg:hover:text-orange-500 transition-colors duration-150 ease-in-out"
      >
        <AtomFormattedDate v-if="item.date" :date="item.date" format="short" />
      </RouterLink>
      <AtomFormattedDate v-else-if="item.date" :date="item.date" format="short" />
    </td>
    <td class="mb-4 h-10 text-right sm:h-10 lg:table-cell lg:mb-0 lg:w-4/12">
      <div class="flex gap-x-2 items-center mb-4 mt-1 sm:justify-end sm:mb-0 sm:mt-0">
        <button
          v-if="isLapOwner && isEditable"
          title="Edit lap time"
          class="
            lg:hover:border-black
            flex
            items-center
            justify-center
            w-8
            h-8
            focus:bg-gray
            bg-transparent
            border border-gray
            rounded-full
            focus:outline-none
            shadow-none
            transition-colors
            duration-150
            ease-in-out
            focus:ring-0
          "
          @click.prevent="buildForm()"
        >
          <BaseIcon class="w-6 h-6"><EditIcon /></BaseIcon>
        </button>
        <button
          v-if="isLapOwner && isEditable"
          title="Delete lap time"
          class="
            lg:hover:border-black
            flex
            items-center
            justify-center
            w-8
            h-8
            focus:bg-gray
            bg-transparent
            border border-gray
            rounded-full
            focus:outline-none
            shadow-none
            transition-colors
            duration-150
            ease-in-out
            focus:ring-0
          "
          @click.prevent="deleteLaptime()"
        >
          <BaseIcon class="w-6 h-6"><DeleteIcon /></BaseIcon>
        </button>
        <button
          class="
            lg:hover:border-black
            flex
            items-center
            justify-center
            px-4
            w-auto
            h-8
            focus:bg-gray
            bg-transparent
            border border-gray
            focus:outline-none
            shadow-none
            transition-colors
            duration-150
            ease-in-out
            focus:ring-0
          "
          @click.prevent="showVideo()"
        >
          Watch
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      item: Object,
      circuitName: String,
      layoutName: String,
      circuitUid: String,
      layoutUid: String,
      eventUid: String,
      isDashboard: Boolean,
      index: Number,
      isEditable: {
        type: Boolean,
        default: true
      }
    },
    emits: ['deleted'],
    data() {
      return {
        actionTitle: 'Update lap time',
        form: {
          cta: 'Update lap time',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'event_uid',
              label: 'Event',
              elem: 'select',
              isRequired: null,
              classes: 'sm:col-span-2',
              children: [
                {
                  tag: 'option',
                  text: 'None',
                  value: 'none'
                }
              ],
              instruction: {
                content: "Can't see the event you've attended?",
                url: '/events',
                cta: 'You can add a past event.'
              }
            },
            {
              id: 'circuit_uid',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'layout_uid',
              label: 'Layout',
              elem: 'select',
              isRequired: 'true',
              children: [],
              disabled: true
            },
            {
              id: 'car_uid',
              label: 'Car',
              elem: 'select',
              isRequired: 'true',
              children: [],
              classes: 'sm:col-span-2',
              instruction: {
                content: "Don't have any cars saved?",
                url: '/dashboard/cars',
                cta: 'Add a car.'
              }
            },
            {
              id: 'date',
              label: 'Date',
              elem: 'input',
              type: 'date',
              min: '2000-01-01',
              max: `${new Date().getFullYear()}-12-31`,
              isRequired: 'true'
            },
            {
              id: 'weather',
              label: 'Weather Conditions',
              elem: 'select',
              children: [
                {
                  tag: 'option',
                  text: 'Dry',
                  value: 'dry'
                },
                {
                  tag: 'option',
                  text: 'Wet',
                  value: 'wet'
                }
              ]
            },
            {
              id: 'laptime',
              label: 'Lap Time',
              placeholder: '00:00.000',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              instruction: {
                content: 'Format minutes:seconds.milliseconds'
              }
            },
            {
              id: 'youtube_id',
              label: 'YouTube Video URL',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              disabled: true
            },
            {
              id: 'private',
              label: 'Do not display this lap time publicly',
              elem: 'input',
              type: 'checkbox',
              isRequired: null,
              classes: 'sm:col-span-2 flex justify-end flex-row-reverse'
            }
          ]
        }
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser',
        allCircuits: 'circuits/getCircuits',
        today: 'getToday'
      }),
      isLapOwner() {
        return this.item.user_uid === (this.loggedInUser && this.loggedInUser.id)
      },
      title() {
        return this.circuitName && this.layoutName
          ? this.layoutName !== 'Full Circuit'
            ? `${this.circuitName} - ${this.layoutName}`
            : this.circuitName
          : this.item.layout_name !== 'Full Circuit'
          ? `${this.item.circuit_name} - ${this.item.layout_name}`
          : this.item.circuit_name
      }
    },
    created() {
      if (this.$route.query.u && this.$route.query.u === this.item.video_uid) this.showVideo()
    },
    methods: {
      async showVideo() {
        this.$store.dispatch('setOverlay', {
          youtube_id: this.item.youtube_id,
          uid: this.item.video_uid,
          liked: this.item.liked ? this.item.liked : null,
          video_uid: this.item.video_uid,
          url: this.item.video_uid,
          owner_uid: this.item.owner_uid,
          title: this.title,
          content: this.item.description,
          meta: {
            username: this.item.username,
            first_name: this.item.first_name,
            last_name: this.item.last_name,
            url: this.item.slug,
            lap_time: this.item.laptime,
            weather: this.item.weather
          }
        })
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        await Promise.all([
          this.$store.dispatch('circuits/getCircuits'),
          this.getCircuitLayouts(this.form),
          this.getUserEvents(),
          this.getUserCars()
        ])

        await this.setCircuits()
        await this.setEvents()
        await this.setCars()
        await this.setMaxDate()

        const date = this.item.date.split('-')
        const formattedDate = `${date[0]}-${date[1]}-${date[2].slice(0, 2)}`
        const duration = this.item.laptime
        let string
        let milliseconds = parseInt(duration % 1000)
        let seconds = parseInt((duration / 1000) % 60)
        let minutes = parseInt((duration / (1000 * 60)) % 60)

        seconds = seconds < 10 ? '0' + seconds : seconds
        string = `${seconds}${milliseconds ? `.${milliseconds.toString().padEnd(3, '0')}` : ''}`
        if (!!minutes) string = `${minutes}:${string}`

        this.form.initial = {
          youtube_id: `https://www.youtube.com/watch?v=${this.item.youtube_id}`,
          event_uid: this.eventUid,
          circuit_uid: this.circuitUid,
          layout_uid: this.layoutUid,
          date: formattedDate,
          laptime: string,
          private: this.item.private,
          weather: this.item.weather,
          car_uid: this.item.car_uid
        }

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          method: 'PUT',
          endpoint: `/laptime/${this.item.uid}`,
          errorMessage: "Sorry we couldn't edit your lap time.",
          successMessage: 'Your lap time has been updated.'
        })
      },
      async deleteLaptime() {
        try {
          await this.apiRequest(`/laptime/${this.item.uid}`, null, null, { Authorization: this.userJWT }, 'DELETE')

          this.$store.dispatch('setDialog', {
            title: 'Lap time Deleted!',
            content: '<p>The lap time has been deleted.</p>',
            ctaTitle: 'Close',
            confirmed: true
          })

          this.$emit('deleted', this.item.uid)
        } catch (e) {
          if (e) console.log(`Error deleting lap time: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't delete this lap time. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        }
      },
      async getCircuitLayouts() {
        if (this.circuitUid)
          try {
            const circuitLayouts = await this.apiRequest(`/circuit/${this.circuitUid}/layouts`, null, null, {
              Authorization: this.userJWT
            })

            const layoutFormField = this.form.fields.find((field) => field.id === 'layout_uid')

            circuitLayouts.forEach((layout) => {
              layoutFormField.children.push({
                tag: 'option',
                text: layout.name,
                value: layout.layout_uid
              })
            })

            layoutFormField.selected = this.layoutUid !== null ? this.layoutUid : null
            layoutFormField.disabled = this.layoutUid !== null ? null : true
          } catch (e) {
            if (e) console.log(`Error getting layouts: ${e.status} ${e.data}`)
          }
      },
      async getUserEvents() {
        try {
          this.events = await this.apiRequest(
            `/user/${this.loggedInUser.id}/events`,
            null,
            { basic: true, start: '2000-01-01', end: this.today },
            {
              Authorization: this.userJWT
            }
          )
        } catch (e) {
          if (e) console.log('Error getEvents')
        }
      },
      async getUserCars() {
        try {
          this.cars = await this.apiRequest(`/user/${this.loggedInUser.id}/cars`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting cars: ${e.status} ${e.data}`)
        }
      },
      async setEvents() {
        const eventFormField = this.form.fields.find((field) => field.id === 'event_uid')
        const pastEvents = this.events.filter((event) => {
          return Date.parse(event.start_date) <= Date.now() ? event : null
        })
        const re = /(\d{4})[-. \/](\d\d)[-. \/](\d\d)/

        pastEvents.forEach((event) => {
          const date = event.start_date.slice(0, 10).replace(re, '$3/$2/$1')
          let text = `${date} - ${event.circuit} `

          if (event.slug.includes('evening')) text += `- Evening `
          text += `(${event.organiser})`

          eventFormField.children.push({
            tag: 'option',
            text,
            value: event.uid
          })
        })

        eventFormField.selected = this.eventUid ? this.eventUid : this.item.event_uid
      },
      async setCars() {
        const carFormField = this.form.fields.find((field) => field.id === 'car_uid')

        this.cars.forEach((car) => {
          carFormField.children.push({
            tag: 'option',
            text: `${car.car_name} (${car.manufacturer} - ${car.model})`,
            value: car.uid
          })
        })
      },
      async setCircuits() {
        const circuitFormField = this.form.fields.find((field) => field.id === 'circuit_uid')

        this.allCircuits.forEach((circuit) => {
          circuitFormField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.uid
          })
        })
      },
      async setMaxDate() {
        const date = this.form.fields.find((field) => field.id === 'date')

        date.max = this.today
      }
    }
  }
</script>
