<template>
  <div
    :class="[isSimple ? null : 'relative', classes ? classes : null, deleted ? 'opacity-0 pointer-events-none' : '']"
  >
    <component
      :is="isSimple ? 'div' : link ? 'button' : 'div'"
      :class="[!isSimple ? 'aspect-w-3 aspect-h-2 group relative bg-gray overflow-hidden' : null]"
      class="flex items-center justify-center w-full h-full"
      @click.prevent="isSimple ? null : link ? showImage() : null"
    >
      <img
        :class="[
          'absolute h-full w-full object-cover z-10 transform-gpu transition-all ease-in-out duration-300 delay-150',
          !isImageLoaded ? 'opacity-0' : 'opacity-100'
        ]"
        :src="imagePath"
        alt="Image Alt"
        @load="imageLoaded"
      />
      <span
        :class="[
          'block bg-orange-500 w-full h-full absolute top-0 left-0 bottom-0 right-0 z-10 transform-gpu -skew-x-12 transition-all swipe duration-300',
          !isImageLoaded ? '-translate-x-120' : 'translate-x-120'
        ]"
      ></span>
      <div
        :class="[
          'text-white flex items-center justify-center transform-gpu transition-all ease-in-out duration-300',
          isImageLoaded ? 'opacity-0' : 'opacity-100'
        ]"
      >
        <AtomLogo />
      </div>
    </component>
    <ul
      v-if="!thumbnail && loggedInUser && !hideActions && !deleted"
      class="absolute z-10 bottom-2 left-2 flex items-end justify-between mt-2"
    >
      <!-- Like -->
      <li>
        <button
          :class="[
            'text-white rounded-full disabled:opacity-50 disabled:cursor-default mr-2 lg:mb-0 w-10 h-10 inline-flex items-center justify-center border-0 text-sm font-medium focus:outline-none transition-colors ease-in-out duration-300',
            liked
              ? 'bg-orange-500 lg:hover:bg-orange-300 active:bg-orange-700'
              : 'bg-black bg-opacity-40 lg:hover:bg-orange-500'
          ]"
          :disabled="isLiking"
          :title="liked ? 'Unlike photograph' : 'Like photograph'"
          @click.prevent="like"
        >
          <BaseIcon class="w-7 h-7"><LoveIcon /></BaseIcon>
        </button>
      </li>
      <!-- Likes -->
      <li>
        <p
          v-if="likes > 0"
          class="
            inline-flex
            items-center
            justify-center
            p-2
            px-4
            w-full
            text-white
            antialiased
            font-bold
            bg-black bg-opacity-40
            sm:w-auto
          "
        >
          {{ likes }} like<template v-if="likes > 1">s</template>
        </p>
      </li>
    </ul>
    <ul
      v-if="!thumbnail && loggedInUser && !hideActions && !deleted"
      class="absolute z-10 bottom-2 right-2 flex items-end justify-between mt-2"
    >
      <!-- Tag -->
      <li v-if="!hideTagAction">
        <button
          :class="[
            'text-white rounded-full disabled:opacity-50 disabled:cursor-default mr-2 lg:mb-0 w-10 h-10 inline-flex items-center justify-center border-0 text-sm font-medium transition-colors ease-in-out duration-300',
            tagged
              ? 'text-white bg-orange-500 lg:hover:bg-orange-300 active:bg-orange-700'
              : 'bg-black bg-opacity-40 lg:hover:bg-orange-500'
          ]"
          :disabled="isTagging"
          :title="tagged ? 'Untag photograph' : 'tag photograph'"
          @click.prevent="tag"
        >
          <BaseIcon class="w-7 h-7"><UserAddIcon /></BaseIcon>
        </button>
      </li>
      <!-- More -->
      <li v-if="!hideMoreActions">
        <button
          :class="[
            'text-white bg-black lg:hover:bg-blue-500font-bold antialiased inline-flex items-center justify-center w-10 h-10 transition-colors ease-in-out duration-300',
            isMenuVisible
              ? 'bg-white text-orange-500 lg:hover:bg-white lg:hover:text-orange-500'
              : 'bg-black bg-opacity-40 lg:hover:bg-white lg:hover:text-orange-500'
          ]"
          :title="isMenuVisible ? 'Hide actions' : 'View more actions'"
          @click.prevent="toggleMenu"
        >
          <BaseIcon class="w-8 h-8">
            <DotsIcon />
          </BaseIcon>
        </button>
        <ul :class="[isMenuVisible ? 'grid gap-y-1 absolute bottom-12' : 'hidden']">
          <!-- Report -->
          <li v-if="!isPhotographOwner">
            <button
              class="
                lg:hover:bg-orange-500
                inline-flex
                items-center
                justify-center
                g:mb-0
                mr-2
                w-10
                h-10
                text-white text-sm
                font-medium
                bg-black bg-opacity-40
                border-0
                rounded-full
                focus:outline-none
                disabled:cursor-default
                transition-colors
                duration-300
                ease-in-out
              "
              :disabled="isReporting"
              title="Report photograph"
              @click.prevent="report"
            >
              <BaseIcon class="w-7 h-7"><ReportIcon /></BaseIcon>
            </button>
          </li>
          <!-- Delete -->
          <li v-if="isPhotographOwner || isAdmin">
            <button
              class="
                lg:hover:bg-orange-500
                inline-flex
                items-center
                justify-center
                mr-2
                w-10
                h-10
                text-white text-sm
                font-medium
                bg-black bg-opacity-40
                border-0
                rounded-full
                focus:outline-none
                disabled:cursor-default
                transition-colors
                duration-300
                ease-in-out
                lg:mb-0
              "
              :disabled="isDeleting"
              title="Delete photograph"
              @click.prevent="deletePhotograph"
            >
              <BaseIcon class="w-7 h-7"><DeleteIcon /></BaseIcon>
            </button>
          </li>
          <!-- Cover-->
          <li v-if="!hideAlbumActions">
            <button
              v-if="isPhotographOwner || isAdmin"
              :class="[
                'text-white rounded-full disabled:cursor-default mr-2 lg:mb-0 w-10 h-10 inline-flex items-center justify-center border-0 text-sm font-medium focus:outline-none transition-colors ease-in-out duration-300',
                coverPhoto ? 'bg-orange-500' : 'bg-black bg-opacity-40 lg:hover:bg-orange-500 lg:hover:bg-opacity-100'
              ]"
              :disabled="coverPhoto"
              :title="coverPhoto ? null : 'Set as cover photograph'"
              @click.prevent="cover"
            >
              <BaseIcon class="w-7 h-7"><FavIcon /></BaseIcon>
            </button>
          </li>
          <!-- View album -->
          <li v-if="hideAlbumActions && albumUrl">
            <RouterLink
              :to="albumUrl"
              title="View album"
              class="
                lg:hover:bg-orange-500
                inline-flex
                items-center
                justify-center
                mr-2lg:mb-0
                w-10
                h-10
                text-white text-sm
                font-medium
                bg-black bg-opacity-40
                border-0
                rounded-full
                focus:outline-none
                disabled:cursor-default
                transition-colors
                duration-300
                ease-in-out
              "
            >
              <BaseIcon class="w-7 h-7">
                <PhotographIcon />
              </BaseIcon>
            </RouterLink>
          </li>
          <!-- Tag -->
          <li v-if="hideTagAction">
            <button
              :class="[
                'text-white rounded-full disabled:opacity-50 disabled:cursor-default mr-2 lg:mb-0 w-10 h-10 inline-flex items-center justify-center border-0 text-sm font-medium transition-colors ease-in-out duration-300',
                tagged
                  ? 'text-white bg-orange-500 lg:hover:bg-orange-300 active:bg-orange-700'
                  : 'bg-black bg-opacity-40 lg:hover:bg-orange-500'
              ]"
              :disabled="isTagging"
              :title="tagged ? 'Untag photograph' : 'tag photograph'"
              @click.prevent="tag"
            >
              <BaseIcon class="w-7 h-7"><UserAddIcon /></BaseIcon>
            </button>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'
  import axios from 'axios'

  export default {
    props: {
      event: String,
      albumUid: String,
      photograph: Object,
      thumbnail: String,
      coverPhoto: Boolean,
      hideActions: Boolean,
      organisersAlbum: {
        type: Boolean,
        default: false
      },
      hideTagAction: {
        type: Boolean,
        default: false
      },
      isSimple: {
        type: Boolean,
        default: false
      },
      hideMoreActions: {
        type: Boolean,
        default: false
      },
      hideAlbumActions: {
        type: Boolean,
        default: false
      },
      classes: Array,
      link: {
        type: Boolean,
        default: false
      },
      width: {
        type: Number,
        default: 768
      }
    },
    emits: ['deleted', 'updated', 'menu', 'liked'],
    data() {
      return {
        likes: this.photograph ? parseInt(this.photograph.likes) : null,
        uid: this.photograph ? this.photograph.uid : null,
        event_uid: this.event,
        liked: this.photograph ? this.photograph.liked : null,
        tagged: this.photograph ? this.photograph.tagged : null,
        tags: this.photograph ? this.photograph.tags : null,
        // coverPhoto: this.photograph ? this.photograph.cover_photo : false,
        isLiking: false,
        isTagging: false,
        isDeleting: false,
        isReporting: false,
        deleted: false,
        isMenuVisible: false,
        isImageLoaded: null
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser'
      }),
      fullSizePath() {
        return `${process.env.VUE_APP_IMGIX}/${this.photograph ? this.photograph.path : this.thumbnail}?w=1920&ar=1.5`
      },
      imagePath() {
        return `${process.env.VUE_APP_IMGIX}/${this.photograph ? this.photograph.path : this.thumbnail}?w=${this.width}`
      },
      albumUrl() {
        return this.photograph.album_uid ? `/album/${this.photograph.album_uid}` : false
      },
      isPhotographOwner() {
        return this.photograph ? this.photograph.owner_uid === this.loggedInUser.id : false
      },
      isAdmin() {
        return this.loggedInUser && this.loggedInUser.admin
      }
    },
    // created() {
    //   if (this.$route.query.u && this.$route.query.u === this.photograph.uid) this.showImage()
    // },
    methods: {
      async like() {
        try {
          this.isLiking = true

          await this.apiRequest(
            `/like`,
            this.liked
              ? {
                  u: this.photograph.uid
                }
              : {
                  photograph_uid: this.photograph.uid,
                  n: this.photograph.owner_uid,
                  o: this.organisersAlbum
                },
            null,
            {
              Authorization: this.userJWT
            },
            this.liked ? 'DELETE' : 'POST'
          )

          this.isLiking = false

          this.liked = !this.liked
          this.likes = this.liked ? this.likes + 1 : this.likes - 1
          this.$emit('liked', this.uid)
        } catch (e) {
          if (e) console.log(`Error liking photograph: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't update your like, please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
          this.isLiking = false
        }
      },
      async cover() {
        try {
          this.isCovering = true

          await this.apiRequest(
            `/album/${this.albumUid}/cover`,
            {
              photographUid: this.photograph.uid
            },
            null,
            {
              Authorization: this.userJWT
            },
            'PUT'
          )

          this.isCovering = false
          this.$emit('updated', this.uid)
        } catch (e) {
          if (e) console.log(`Error tagging photograph: ${e.status} ${e.data}`)
        }
      },
      async tag() {
        const data = this.event_uid ? { eventUid: this.event_uid } : null

        try {
          this.isTagging = true

          await this.apiRequest(
            `/photograph/${this.uid}/tag`,
            data,
            null,
            {
              Authorization: this.userJWT
            },
            'POST'
          )

          this.isTagging = false

          this.tagged = !this.tagged
        } catch (e) {
          if (e) console.log(`Error tagging photograph: ${e.status} ${e.data}`)
        }
      },
      async report() {
        const user = this.loggedInUser
        const url = process.env.VUE_APP_URL

        try {
          this.isReporting = true

          await this.apiRequest(
            '/report',
            {
              template: 'report_photograph',
              content: {
                photograph: {
                  url: `album/${this.albumUid}?u=${this.photograph.uid}`,
                  src: this.imagePath
                },
                user: {
                  first_name: this.loggedInUser.first_name,
                  last_name: this.loggedInUser.last_name,
                  username: this.loggedInUser.username
                }
              },
              verification: this.loggedInUser.id
            },
            null,
            {
              Authorization: this.userJWT
            },
            'POST'
          )

          this.isReporting = false
          this.toggleMenu()

          this.$store.dispatch('setDialog', {
            title: 'Report Sent',
            content: '<p>Thanks for reporting this photo.</p>',
            ctaTitle: 'Close',
            confirmed: true
          })
        } catch (e) {
          if (e) console.log(`Error reporting photograph: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't report this photograph. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        }
      },
      async deletePhotograph() {
        try {
          this.isDeleting = true

          console.log('Deleting photograph UID', this.uid)

          await this.apiRequest(
            `/photograph/${this.uid}`,
            null,
            null,
            {
              Authorization: this.userJWT
            },
            'DELETE'
          )

          this.deleted = true
          this.$emit('deleted', this.uid)
        } catch (e) {
          if (e) console.log(`Error deleting photograph: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't delete this photograph. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        } finally {
          this.isDeleting = false
        }
      },
      async toggleMenu() {
        this.isMenuVisible = !this.isMenuVisible
        this.$emit('menu', this.isMenuVisible)
      },
      async showImage() {
        this.$store.dispatch('setOverlay', {
          imagePath: this.fullSizePath,
          uid: this.uid,
          photograph_uid: this.uid,
          url: this.uid,
          owner_uid: this.photograph.owner_uid,
          liked: this.liked,
          tags: this.tags
        })
      },
      imageLoaded() {
        this.isImageLoaded = true
      }
    }
  }
</script>
