<template>
  <svg :width="width" :height="height" viewBox="0 0 49 29" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M19.0779 0H47.1141C47.9163 0 48.3919 0.897076 47.9418 1.56109L44.5797 6.52115C43.8354 7.61917 42.5952 8.27679 41.2687 8.27679H25.2004C24.5388 8.27679 23.92 8.604 23.5475 9.15082L17.8172 17.563C17.6309 17.8364 17.3215 18 16.9907 18H8.87526C8.07521 18 7.59909 17.1072 8.04487 16.4428L19.0779 0Z"
    />
    <path
      fill="currentColor"
      d="M29.7303 29L2.07774 29C1.23974 29 0.773411 28.0311 1.2962 27.3761L5.40573 22.2278C6.16474 21.2769 7.31525 20.7232 8.53191 20.7232L23.6446 20.7232C24.3032 20.7232 24.9195 20.399 25.2927 19.8564L31.084 11.4334C31.2706 11.1621 31.5787 11 31.908 11L40.1081 11C40.9115 11 41.3869 11.8994 40.9344 12.5632L29.7303 29Z"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      width: {
        type: Number,
        default: 49
      },
      height: {
        type: Number,
        default: 29
      },
      classes: Array
    }
  }
</script>
