<template>
  <div class="relative">
    <div class="relative flex items-start space-x-2">
      <div class="relative">
        <img
          v-if="hasPhoto"
          class="flex items-center justify-center w-8 h-8 rounded-full ring-white ring-8"
          :src="userPhoto"
          :alt="reply.username"
        />
        <div v-else class="flex items-center justify-center p-2 w-8 h-8 bg-gray rounded-full"><AtomLogo /></div>
      </div>
      <div class="group flex-1 min-w-0 space-y-1">
        <div class="flex items-center">
          <div class="flex-1 w-full space-y-0 lg:flex-none">
            <div
              class="relative block p-2 border rounded-sm lg:inline-block"
              :class="[highlight ? 'bg-gray-100 border-gray-100' : 'bg-gray-100 border-gray-100']"
            >
              <div>
                <div class="">
                  <RouterLink
                    :to="`/${reply.username}`"
                    class="lg:hover:text-orange-500 font-bold transition-colors duration-150 ease-in-out"
                  >
                    {{ reply.first_name }} {{ reply.last_name }}
                  </RouterLink>
                </div>
              </div>
              <div
                class="inline-block whitespace-pre-wrap outline-none"
                :class="[hasLikes ? 'pr-12' : null]"
                contenteditable="false"
              >
                <p v-html="content"></p>
              </div>
              <div
                v-if="hasLikes"
                class="
                  absolute
                  -bottom-4
                  -right-0
                  flex
                  items-center
                  p-1
                  pr-2
                  text-sm
                  bg-white
                  rounded-sm
                  pointer-events-none
                "
              >
                <BaseIcon class="mr-1 w-6 h-6"><LoveIcon /></BaseIcon>
                {{ likes }}
              </div>
            </div>
          </div>
        </div>
        <div class="relative flex items-center justify-between mb-2 pl-2 text-sm space-x-2">
          <div class="flex items-center space-x-2">
            <button
              v-if="loggedInUser"
              class="
                lg:hover:border-black
                font-bold
                border-b border-transparent
                focus:outline-none
                opacity-50
                transition-colors
                duration-150
                ease-in-out
              "
              :class="[liked ? 'text-orange-500 lg:hover:border-orange-500 opacity-100' : null]"
              :disabled="isLiking"
              :title="liked ? 'Unlike photograph' : 'Like photograph'"
              @click.prevent="likeComment"
            >
              Like
            </button>
            <button
              v-if="loggedInUser"
              class="
                lg:hover:border-black
                font-bold
                border-b border-transparent
                focus:outline-none
                opacity-50
                transition
                duration-150
                ease-in-out
              "
              :class="[isReplying ? 'border-black' : null]"
              title="Reply to comment"
              @click.prevent="replyComment"
            >
              Reply
            </button>
            <p>
              {{ date }}
            </p>
            <div v-if="loggedInUser" class="relative flex">
              <button
                class="
                  lg:group-hover:opacity-100
                  focus:outline-none
                  opacity-50
                  transition
                  duration-150
                  ease-in-out
                  lg:opacity-0
                "
                :class="[isMenuVisible ? 'lg:opacity-100' : null]"
                :title="isMenuVisible ? 'Hide actions' : 'View more actions'"
                @click.prevent="toggleMenu"
              >
                <BaseIcon class="w-6 h-6">
                  <DotsIcon />
                </BaseIcon>
              </button>
              <div
                v-if="isMenuVisible"
                class="absolute z-10 right-0 top-full w-40 text-sm bg-white border border-gray rounded-sm"
              >
                <ul class="py-2 space-y-2">
                  <!-- <li v-if="isCommentAuthor">
                    <button
                      class="
                        lg:hover:text-orange-500
                        flex
                        px-4
                        w-full
                        focus:border-orange-500
                        focus:outline-none
                        transition
                        duration-150
                        ease-in-out
                      "
                    >
                      Edit reply
                    </button>
                  </li> -->
                  <li v-if="isCommentAuthor">
                    <button
                      class="
                        lg:hover:text-orange-500
                        flex
                        px-4
                        w-full
                        focus:border-orange-500
                        focus:outline-none
                        disabled:cursor-default
                        transition
                        duration-150
                        ease-in-out
                      "
                      :disabled="isDeleting"
                      title="Delete comment"
                      @click.prevent="deleteComment"
                    >
                      Delete reply
                    </button>
                  </li>
                  <li v-if="!isCommentAuthor">
                    <button
                      class="
                        w-fullflex
                        lg:hover:text-orange-500
                        flex
                        items-center
                        px-4
                        focus:border-orange-500
                        focus:outline-none
                        disabled:cursor-default
                        transition
                        duration-150
                        ease-in-out
                      "
                      :disabled="isReporting"
                      title="Report comment"
                      @click.prevent="reportComment"
                    >
                      Report comment
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import * as timeago from 'timeago.js'
  import Filter from 'bad-words'

  function linkify(inputText) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
    replacedText = inputText.replace(
      replacePattern1,
      '<a class="border-b border-b-gray" href="$1" target="_blank">$1</a>'
    )

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
    replacedText = replacedText.replace(
      replacePattern2,
      '$1<a class="border-b border-b-gray" href="http://$2" target="_blank">$2</a>'
    )

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
    replacedText = replacedText.replace(replacePattern3, '<a class="border-b border-b-gray" href="mailto:$1">$1</a>')

    return replacedText
  }

  const localeFunc = (number, index, totalSec) => {
    return [
      ['just now', 'right now'],
      ['%ss', 'in %ss'],
      ['1m', 'in 1m'],
      ['%sm', 'in %sm'],
      ['1h', 'in 1h'],
      ['%sh', 'in %sh'],
      ['1d', 'in 1d'],
      ['%sd', 'in %sd'],
      ['1w', 'in 1w'],
      ['%sw', 'in %sw'],
      ['1mo', 'in 1mo'],
      ['%smo', 'in %smo'],
      ['1yr', 'in 1yr'],
      ['%syr', 'in %syr']
    ][index]
  }

  timeago.register('en-short', localeFunc)

  export default {
    props: {
      overlayUid: String,
      parentUid: String,
      reply: Object,
      highlight: {
        type: Boolean,
        default: false
      }
    },
    emits: ['deleted', 'added', 'reply'],
    data() {
      return {
        isMenuVisible: false,
        isReporting: false,
        isDeleting: false,
        isSaving: false,
        isLiking: false,
        liked: this.reply ? this.reply.liked : null,
        likes: this.reply ? parseInt(this.reply.likes) : null
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser'
      }),
      content() {
        const filter = new Filter()
        const plaintoAnchors = linkify(this.reply.comment)
        const textFilter = this.reply.comment.replace(
          /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
          ''
        )

        return !!plaintoAnchors
          ? textFilter.length > 2
            ? filter.clean(plaintoAnchors).replace(/\n/g, '<br />')
            : plaintoAnchors.replace(/\n/g, '<br />')
          : null
      },
      date() {
        return timeago.format(this.reply.created_at, 'en-short')
      },
      hasPhoto() {
        return this.reply.user_cover_photo
      },
      hasCoverPhoto() {
        return this.loggedInUser.cover_photo
      },
      userPhoto() {
        const reply = this.reply

        return reply.user_cover_photo
          ? `${process.env.VUE_APP_IMGIX}/users/${reply.user_uid}/${reply.user_cover_photo}?w=64&h=64&fit=crop`
          : null
      },
      hasLikes() {
        return !!this.likes
      },
      isCommentAuthor() {
        return this.loggedInUser.id === this.reply.user_uid
      }
    },
    methods: {
      toggleMenu() {
        this.isMenuVisible = !this.isMenuVisible
      },
      replyComment() {
        this.$emit('reply')
      },
      async deleteComment() {
        this.isDeleting = true

        try {
          await this.apiRequest(
            `/comment/${this.reply.uid}`,
            null,
            null,
            {
              Authorization: this.userJWT
            },
            'DELETE'
          )

          this.$emit('deleted', this.reply.uid)
        } catch (e) {
          if (e) console.log(`Error deleting comment: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't delete this reply. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        }

        this.isDeleting = false
        this.isMenuVisible = false
      },
      async reportComment() {
        try {
          this.isReporting = true

          await this.apiRequest(
            '/report',
            {
              template: 'report_comment',
              content: {
                comment: {
                  content: this.reply.comment,
                  url: this.$route.fullPath.substring(1),
                  first_name: this.reply.first_name,
                  last_name: this.reply.last_name,
                  username: this.reply.username
                },
                user: {
                  first_name: this.loggedInUser.first_name,
                  last_name: this.loggedInUser.last_name,
                  username: this.loggedInUser.username
                }
              },
              verification: this.loggedInUser.id
            },
            null,
            {
              Authorization: this.userJWT
            },
            'POST'
          )

          this.$store.dispatch('setDialog', {
            title: 'Report Sent',
            content: '<p>Thanks for reporting this reply.</p>',
            ctaTitle: 'Close',
            confirmed: true
          })
        } catch (e) {
          if (e) console.log(`Error reporting reply: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't report this reply. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        }

        this.isReporting = false
        this.isMenuVisible = false
      },
      async likeComment() {
        try {
          this.isLiking = true

          await this.apiRequest(
            `/like`,
            this.liked
              ? {
                  u: this.reply.uid
                }
              : {
                  reply_uid: this.reply.uid,
                  n: this.reply.user_uid,
                  parent_uid: this.parentUid,
                  overlay_uid: this.overlayUid
                },
            null,
            {
              Authorization: this.userJWT
            },
            this.liked ? 'DELETE' : 'POST'
          )

          this.isLiking = false

          this.liked = !this.liked
          this.likes = this.liked ? this.likes + 1 : this.likes - 1
        } catch (e) {
          if (e) console.log(`Error liking comment: ${e.status} ${e.data}`)
        }
      }
    }
  }
</script>
