<template>
  <article :class="classes">
    <div class="flex items-center justify-between">
      <header>
        <h3 class="mt-4 text-xl font-bold">{{ leaderboard.name }}</h3>
        <h4 class="text-lg font-bold">
          {{ leaderboard.circuit_name }}
          <template v-if="leaderboard.layout_name !== 'None'">- {{ leaderboard.layout_name }}</template>
          <span class="text-sm font-normal"> ({{ leaderboard.length }} miles) </span>
        </h4>
        <h4 v-if="leaderboard.event_uid" class="text-lg font-bold">
          <RouterLink :to="eventUrl">
            {{ leaderboard.organiser_name }}
            <span class="text-sm font-normal">
              <AtomFormattedDate :date="leaderboard.start_date" />
            </span>
          </RouterLink>
        </h4>
      </header>
      <div class="flex gap-x-2 items-center sm:justify-end">
        <button
          title="Edit leaderboard"
          class="
            lg:hover:border-black
            flex
            items-center
            justify-center
            w-8
            h-8
            focus:bg-gray
            bg-transparent
            border border-gray
            rounded-full
            focus:outline-none
            shadow-none
            transition-colors
            duration-150
            ease-in-out
            focus:ring-0
          "
          @click.prevent="buildForm()"
        >
          <BaseIcon class="w-6 h-6"><EditIcon /></BaseIcon>
        </button>
        <button
          title="Delete leaderboard"
          class="
            lg:hover:border-black
            flex
            items-center
            justify-center
            w-8
            h-8
            focus:bg-gray
            bg-transparent
            border border-gray
            rounded-full
            focus:outline-none
            shadow-none
            transition-colors
            duration-150
            ease-in-out
            focus:ring-0
          "
          @click.prevent="deleteLeaderboard()"
        >
          <BaseIcon class="w-6 h-6"><DeleteIcon /></BaseIcon>
        </button>
      </div>
    </div>
    <MoleculeLapTimes
      v-if="leaderboard.laptimes"
      :circuit-name="leaderboard.circuit_name"
      :layout-name="leaderboard.layout_name"
      :laptimes="leaderboard.laptimes"
    />
  </article>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      leaderboard: Object,
      classes: Array
    },
    emits: ['deleted', 'update-leaderboards'],
    data() {
      return {
        actionTitle: 'Update leaderboard',
        users: null,
        events: null,
        form: {
          cta: 'Update leaderboard',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'name',
              label: 'Name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'users_uid',
              label: 'Friends',
              elem: 'select',
              isRequired: null,
              classes: 'sm:col-span-2',
              multiple: true,
              isRequired: 'true',
              size: 5,
              children: []
            },
            {
              id: 'event_uid',
              label: 'Event',
              elem: 'select',
              isRequired: null,
              classes: 'sm:col-span-2',
              children: [
                {
                  tag: 'option',
                  text: 'None',
                  value: 'none'
                }
              ]
            },
            {
              id: 'circuit_uid',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'layout_uid',
              label: 'Layout',
              elem: 'select',
              isRequired: 'true',
              children: [],
              disabled: true
            }
          ]
        }
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser',
        allCircuits: 'circuits/getCircuits',
        today: 'getToday'
      }),
      eventUrl() {
        return `/event/${this.leaderboard.slug}`
      },
      isAdmin() {
        return this.loggedInUser && this.loggedInUser.admin
      }
    },
    methods: {
      async deleteLeaderboard() {
        try {
          await this.apiRequest(
            `/leaderboard/${this.leaderboard.uid}`,
            null,
            null,
            { Authorization: this.userJWT },
            'DELETE'
          )

          this.$store.dispatch('setDialog', {
            title: 'Leaderboard Deleted!',
            content: '<p>The leaderboard has been deleted.</p>',
            ctaTitle: 'Close',
            confirmed: true
          })

          this.$emit('deleted', this.leaderboard.uid)
        } catch (e) {
          if (e) console.log(`Error deleting leaderboard: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't delete this leaderboard. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        }
      },
      async buildForm() {
        await Promise.all([
          this.$store.dispatch('circuits/getCircuits'),
          this.getCircuitLayouts(),
          this.getEvents(),
          this.getUserFriends()
        ])

        await this.setCircuits()
        await this.setEvents()
        await this.setFriends()

        this.form.initial = {
          name: this.leaderboard.name,
          event_uid: this.leaderboard.event_uid,
          circuit_uid: this.leaderboard.circuit_uid,
          layout_uid: this.leaderboard.layout_uid,
          users_uid: this.leaderboard.users
        }

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          method: 'PUT',
          endpoint: `/leaderboard/${this.leaderboard.uid}`,
          errorMessage: "Sorry we couldn't edit your leaderboard.",
          successMessage: 'Your leaderboard has been updated.'
        })
      },
      async getCircuitLayouts() {
        if (this.leaderboard.circuit_uid)
          try {
            const circuitLayouts = await this.apiRequest(
              `/circuit/${this.leaderboard.circuit_uid}/layouts`,
              null,
              null,
              {
                Authorization: this.userJWT
              }
            )
            const layoutFormField = this.form.fields.find((field) => field.id === 'layout_uid')

            circuitLayouts.forEach((layout) => {
              layoutFormField.children.push({
                tag: 'option',
                text: layout.name,
                value: layout.layout_uid
              })
            })

            layoutFormField.selected = this.leaderboard.layout_uid !== null ? this.leaderboard.layout_uid : null
            layoutFormField.disabled = this.leaderboard.layout_uid !== null ? null : true
          } catch (e) {
            if (e) console.log(`Error getting layouts: ${e.status} ${e.data}`)
          }
      },
      async getEvents() {
        try {
          this.events = await this.apiRequest(
            `/user/${this.loggedInUser.id}/events`,
            null,
            { basic: true, start: '2000-01-01', end: this.today },
            {
              Authorization: this.userJWT
            }
          )
        } catch (e) {
          if (e) console.log('Error getEvents')
        }
      },
      async getUserFriends() {
        try {
          this.users = await this.apiRequest(`/user/${this.loggedInUser.id}/following`)

          this.users = this.users.filter((member) => {
            return member.first_name != null && member.last_name != null
          })
        } catch (e) {
          if (e) console.log(`Error getUserFriends`)
        }
      },
      async setEvents() {
        if (!this.events) return

        const eventFormField = this.form.fields.find((field) => field.id === 'event_uid')
        const pastEvents = this.events.filter((event) => {
          return Date.parse(event.start_date) <= Date.now() ? event : null
        })
        const re = /(\d{4})[-. \/](\d\d)[-. \/](\d\d)/

        pastEvents.forEach((event) => {
          const date = event.start_date.slice(0, 10).replace(re, '$3/$2/$1')
          let text = `${date} - ${event.circuit} `

          if (event.slug.includes('evening')) text += `- Evening `
          text += `(${event.organiser})`

          eventFormField.children.push({
            tag: 'option',
            text,
            value: event.uid
          })
        })

        eventFormField.selected = this.leaderboard.event_uid
      },
      async setCircuits() {
        const circuitFormField = this.form.fields.find((field) => field.id === 'circuit_uid')

        this.allCircuits.forEach((circuit) => {
          circuitFormField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.uid
          })
        })

        circuitFormField.selected = this.leaderboard.circuit_uid
      },
      async setFriends() {
        const usersFormField = this.form.fields.find((field) => field.id === 'users_uid')

        usersFormField.children = []

        usersFormField.children.push({
          tag: 'option',
          text: `You (${this.loggedInUser.username})`,
          value: this.loggedInUser.id
        })

        this.users.forEach((user) => {
          usersFormField.children.push({
            tag: 'option',
            text: `${user.first_name} ${user.last_name} (${user.username})`,
            value: user.uid
          })
        })

        usersFormField.selected = this.leaderboard.users
      }
    }
  }
</script>
