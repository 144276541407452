<template>
  <div>Loading…</div>
</template>

<style scoped>
  div,
  div:before,
  div:after {
    animation: loading 1.8s infinite ease-in-out;
    animation-fill-mode: both;
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }

  div {
    animation-delay: -0.16s;
    color: #ffffff;
    font-size: 10px;
    text-indent: -9999em;
    transform: scale(0.5) translateZ(0);

    position: relative;
    top: -12px;
  }

  div:before,
  div:after {
    content: '';

    position: absolute;
    top: 0;
  }

  div:before {
    animation-delay: -0.32s;
    left: -3.5em;
  }

  div:after {
    left: 3.5em;
  }

  @keyframes loading {
    0%,
    80%,
    100% {
      box-shadow: 0 24px 0 -1.3em;
    }
    40% {
      box-shadow: 0 24px 0 0;
    }
  }
</style>
