<template>
  <div class="absolute z-50 bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-white">
    <div class="mx-auto px-4 py-16 max-w-screen-xl sm:px-6 sm:py-24 lg:px-8">
      <div class="text-center">
        <h1
          class="
            text-gray-900
            flex flex-col
            items-center
            justify-center
            text-3xl
            font-extrabold
            tracking-tight
            leading-9
            sm:text-4xl
            sm:leading-10
          "
        >
          <AtomLogo class="mb-8" :width="75" :height="45" />
          Loading…
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>
