<template>
  {{ textDate }}
</template>

<script>
  import { computed } from 'vue'

  export default {
    props: {
      date: String,
      format: String,
      hideDay: Boolean
    },
    setup(props) {
      const textDate = computed(() => {
        const d = new Date(props.date)

        const months = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ]
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        const dayName = days[d.getDay()]
        const nth = function (d) {
          if (d > 3 && d < 21) return 'th'
          switch (d % 10) {
            case 1:
              return 'st'
            case 2:
              return 'nd'
            case 3:
              return 'rd'
            default:
              return 'th'
          }
        }
        const monthName = months[d.getMonth()]
        const zeroPad = (num, places) => String(num).padStart(places, '0')

        let formattedDate = ''

        if (props.format === 'short') {
          formattedDate = d.toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })
        } else if (props.format === 'medium') {
          formattedDate = `${d.getDate()} ${monthName.substring(0, 3)} ${d.getFullYear()}`
        } else {
          formattedDate = `${dayName} ${d.getDate()} ${monthName} ${d.getFullYear()}`
        }

        if (props.hideDay) {
          formattedDate = `${monthName} ${d.getFullYear()}`
        }

        return formattedDate
      })

      return {
        textDate
      }
    }
  }
</script>
