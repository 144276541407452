<template>
  <button
    v-if="user && isAuthenticated"
    class="
      group
      absolute
      z-10
      bottom-0
      left-auto
      right-0
      top-auto
      inline-flex
      items-center
      justify-center
      mt-2
      w-auto
      h-10
      text-sm
      font-medium
      rounded-b-none rounded-sm rounded-tr-none
      focus:outline-none
      overflow-hidden
      transform
      transition
      duration-300
      ease-in-out
      sm:mt-0
    "
    :class="[
      isFollowing ? 'bg-orange-500 text-white' : 'bg-white text-black lg:hover:text-orange-500',
      isUpdating ? 'is-loading' : null
    ]"
    :disabled="isUpdating"
    :title="title"
    @click.prevent="updateFollowStatus"
  >
    <BaseIcon class="w-10 transform transition-all duration-300 ease-in-out">
      <TagIcon v-if="isFollowing" class="w-7 h-7" />
      <UserAddIcon v-else class="w-7 h-7" />
    </BaseIcon>
    <p
      class="mr-4 text-black transition-all duration-300 ease-in-out"
      :class="[isFollowing ? 'text-white' : 'text-black']"
    >
      <template v-if="isFollowing">You're following {{ user.first_name }}</template>
      <template v-else>Follow {{ user.first_name }}</template>
    </p>
  </button>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      user: Object
    },
    data() {
      return {
        isUpdating: false,
        isFollowing: this.user.is_following,
        numFollowers: parseInt(this.user.followers)
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser',
        isAuthenticated: 'auth/getIsAuthenticated',
        displayProfileMenu: 'getProfileMenuDisplay'
      }),
      title() {
        if (this.isFollowing) {
          return `Unfollow ${this.user.username}`
        } else if (this.user && !this.isFollowing) {
          return `Follow ${this.user.username}`
        }

        return 'Follow'
      },
      numOfFollowers() {
        const text = this.numFollowers === 1 ? 'follower' : 'followers'

        return `${this.numFollowers} ${text}`
      }
    },
    methods: {
      async updateFollowStatus() {
        this.isUpdating = true

        await this.apiRequest(
          `/user/${this.user.uid}/follow`,
          null,
          null,
          {
            Authorization: this.userJWT
          },
          'POST'
        )

        this.isFollowing = !this.isFollowing
        this.numFollowers = this.isFollowing ? parseInt(this.numFollowers) + 1 : parseInt(this.numFollowers) - 1
        this.isUpdating = false
      }
    }
  }
</script>

<style scoped>
  svg {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }

  .is-loading svg {
    animation: loading 1.8s infinite ease-in-out;
    animation-delay: 0;
    animation-fill-mode: both;
    opacity: 0.5;
  }

  @keyframes loading {
    0% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
