<template>
  <header :class="[!sidebar ? 'pt-4 lg:pt-8 px-4 md:px-0' : null, classes]">
    <div :class="['mx-auto lg:flex lg:items-center lg:justify-between', isFullWidth ? null : 'max-w-screen-2xl']">
      <component
        :is="!headingTag ? 'h1' : headingTag"
        :class="[
          'flex items-center font-bold leading-tight tracking-tight text-black flex-1 sm:pr-4',
          headingClasses ? headingClasses : 'text-3xl'
        ]"
      >
        <template v-if="!isPageLoading">
          {{ title }}
          <template v-if="subTitle"><br />{{ subTitle }}</template>
        </template>
        <template v-else>Loading…</template>
      </component>
      <div class="flex flex-wrap gap-2 mt-2 lg:mt-0">
        <slot v-if="!isPageLoading" name="filter"></slot>
        <button
          v-if="actionTitle && ((ownerAction && isOwner) || (adminAction && isAdmin) || (!adminAction && !ownerAction))"
          class="
            group
            lg:hover:bg-orange-300
            lg:hover:border-orange-300
            inline-flex
            items-center
            justify-center
            mt-2
            h-10
            text-white text-sm
            font-medium
            bg-orange-500
            border-0
            rounded-sm
            focus:outline-none
            overflow-hidden
            transform
            transition
            duration-300
            ease-in-out
            sm:mt-0
          "
          :title="actionTitle"
          @click.prevent="buttonAction"
        >
          <BaseIcon class="flex-shrink-0 mx-2 w-7 h-7 transform transition-all duration-300 ease-in-out">
            <component :is="icon" />
          </BaseIcon>
          <p class="mr-4 transition-all duration-300 ease-in-out">
            {{ actionTitle }}
          </p>
        </button>
        <slot v-if="!isPageLoading"></slot>
      </div>
    </div>
  </header>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      classes: String,
      headingClasses: Array,
      content: String,
      isPageLoading: Boolean,
      subTitle: String,
      title: String,
      actionTitle: String,
      icon: String,
      sidebar: {
        type: Boolean,
        default: false
      },
      headingTag: String,
      isFullWidth: {
        type: Boolean,
        default: false
      },
      adminAction: {
        type: Boolean,
        default: false
      },
      ownerAction: {
        type: Boolean,
        default: false
      },
      isOwner: {
        type: Boolean,
        default: false
      }
    },
    emits: ['action'],
    computed: {
      ...mapGetters({
        loggedInUser: 'auth/getLoggedInUser'
      }),
      isAdmin() {
        return this.loggedInUser && this.loggedInUser.admin
      }
    },
    methods: {
      buttonAction() {
        this.$emit('action')
      }
    }
  }
</script>
