<template>
  <li v-if="notification" class="relative space-y-1 sm:space-y-0">
    <div class="flex items-start space-x-4">
      <div class="relative">
        <img
          v-if="hasUserPhoto"
          class="flex items-center justify-center w-12 h-12 rounded-full ring-white ring-8"
          :src="userPhoto"
          :alt="notification.username"
        />
        <div v-else class="flex items-center justify-center p-2 w-12 h-12 text-white bg-orange-500 rounded-full">
          <AtomLogo />
        </div>
        <div
          class="
            absolute
            bottom-0
            right-0
            flex
            items-center
            justify-center
            w-6
            h-6
            text-white
            bg-orange-500
            rounded-full
            transform
            translate-x-1 translate-y-1
          "
        >
          <BaseIcon class="w-5 h-5">
            <LoveIcon v-if="notification.like_uid" />
            <CommentIcon v-if="notification.comment_uid" />
            <PhotographIcon v-if="notification.a_e_a_uid" />
            <LeaderboardIcon v-if="notification.a_e_lt_uid && notification.a_e_v_uid" />
            <VideoIcon v-if="!notification.a_e_lt_uid && notification.a_e_v_uid" />
            <EventIcon v-if="notification.a_e_name && notification.a_e_slug" />
          </BaseIcon>
        </div>
      </div>
      <div class="flex-1 flex-wrap w-full space-y-1">
        <div>
          <!-- User -->
          <RouterLink
            v-if="notification.first_name && notification.last_name"
            class="
              lg:hover:border-orange-500
              text-orange-500
              border-b border-transparent
              transition
              duration-150
              ease-in-out
            "
            :to="`/${notification.username}`"
          >
            {{ notification.first_name }} {{ notification.last_name }}
          </RouterLink>
          <span v-else-if="notification.username">Someone</span>
          <span v-else>Tracklimit</span>

          <!-- Action -->
          <span v-if="notification.like_uid"> liked your </span>
          <span v-else-if="notification.comment_uid && notification.c_reply_to_uid"> replied to your </span>
          <span v-else-if="notification.comment_uid && !notification.c_reply_to_uid && notification.a_e_slug">
            added a comment to
          </span>
          <span v-else-if="notification.comment_uid && !notification.c_reply_to_uid"> commented on </span>
          <span v-else-if="notification.a_e_a_uid"> added an </span>
          <span v-else-if="notification.a_e_v_uid"> added a </span>
          <span v-else-if="notification.a_e_name && notification.a_e_slug"> {{ attending }} </span>

          <!-- . Added: Attending an Event -->
          <template
            v-if="
              notification.a_e_name &&
              notification.a_e_slug &&
              !notification.a_e_a_uid &&
              !notification.a_e_v_uid &&
              !notification.a_e_v_uid &&
              !notification.comment_uid
            "
          >
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/event/${notification.a_e_slug}`"
              >{{ notification.a_e_name }} (<AtomFormattedDate :date="notification.a_e_date" format="short" />)
            </RouterLink>
          </template>

          <!-- . Added: Laptime to Event -->
          <template v-if="notification.a_e_v_uid && notification.a_e_lt_uid">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/event/${notification.a_e_slug}?u=${notification.a_e_v_uid}`"
              >lap time</RouterLink
            >
            to
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/event/${notification.a_e_slug}`"
              >{{ notification.a_e_name }} (<AtomFormattedDate :date="notification.a_e_date" format="short" />)
            </RouterLink>
          </template>

          <!-- . Added: Video to Event -->
          <template v-if="notification.a_e_v_uid && !notification.a_e_lt_uid">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/event/${notification.a_e_slug}?u=${notification.a_e_v_uid}`"
              >video</RouterLink
            >
            to
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/event/${notification.a_e_slug}`"
              >{{ notification.a_e_name }} (<AtomFormattedDate :date="notification.a_e_date" format="short" />)
            </RouterLink>
          </template>

          <!-- . Added: Album to Event -->
          <template v-if="notification.a_e_a_uid && notification.a_e_slug">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/album/${notification.a_e_a_uid}`"
              >album</RouterLink
            >
            to
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/event/${notification.a_e_slug}`"
              >{{ notification.a_e_name }} (<AtomFormattedDate :date="notification.a_e_date" format="short" />)
            </RouterLink>
          </template>

          <!-- . Like: Video -->
          <!-- . Like: Album -->
          <!-- . Like: Laptime -->

          <!-- . Like: Photograph -->
          <template v-if="notification.l_p_album_uid && notification.l_p_uid">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/album/${notification.l_p_album_uid}?u=${notification.l_p_uid}`"
              >photograph
            </RouterLink>
          </template>

          <!-- . Like: Comment on photograph -->
          <template v-if="notification.l_c_photograph_uid && notification.l_c_photograph_album_uid">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/album/${notification.l_c_photograph_album_uid}?u=${notification.l_c_photograph_uid}`"
              >comment</RouterLink
            >
            on a photograph in
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/album/${notification.l_c_photograph_album_uid}`"
              >{{ notification.l_c_photograph_album_name }}
            </RouterLink>
          </template>

          <!-- . Like: Comment on album -->
          <template v-if="notification.l_c_album_name && notification.l_c_album_uid">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/album/${notification.l_c_album_uid}#comments`"
              >comment</RouterLink
            >
            on
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/album/${notification.l_c_album_uid}`"
              >{{ notification.l_c_album_name }}
            </RouterLink>
          </template>

          <!-- . Like: Comment on video -->
          <template v-if="notification.l_c_video_uid && notification.l_c_video_circuit">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/videos?u=${notification.l_c_video_uid}`"
              >comment</RouterLink
            >
            on a video at
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/videos?u=${notification.l_c_video_uid}`"
              >{{ notification.l_c_video_circuit
              }}<template v-if="notification.l_c_video_date">
                (<AtomFormattedDate :date="notification.l_c_video_date" format="short" />)</template
              >
            </RouterLink>
          </template>

          <!-- . Like: Comment on organiser -->
          <template v-if="notification.l_c_organiser_name && notification.l_c_organiser_slug">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/organiser/${notification.l_c_organiser_slug}#comments`"
              >comment</RouterLink
            >
            on
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/organiser/${notification.l_c_organiser_slug}`"
              >{{ notification.l_c_organiser_name }}
            </RouterLink>
          </template>

          <!-- . Like: Comment on circuit -->
          <template v-if="notification.l_c_circuit_name && notification.l_c_circuit_slug">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/circuit/${notification.l_c_circuit_slug}#comments`"
              >comment</RouterLink
            >
            on
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/circuit/${notification.l_c_circuit_slug}`"
              >{{ notification.l_c_circuit_name }}
            </RouterLink>
          </template>

          <!-- . Like: Comment on event -->
          <template v-if="notification.l_c_event_slug && notification.l_c_event_date">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/event/${notification.l_c_event_slug}#comments`"
              >comment</RouterLink
            >
            on
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/event/${notification.l_c_event_slug}`"
              >{{ notification.l_c_event_circuit_name }}
              <template v-if="notification.l_c_event_date"
                >(<AtomFormattedDate :date="notification.l_c_event_date" format="short" />)</template
              >
            </RouterLink>
          </template>

          <!-- . Comment/Reply on photograph -->
          <template v-if="notification.c_photograph_uid && notification.c_photograph_album_uid">
            <template v-if="notification.c_reply_to_uid"
              ><RouterLink
                class="
                  lg:hover:border-orange-500
                  text-orange-500
                  border-b border-transparent
                  transition
                  duration-150
                  ease-in-out
                "
                :to="`/album/${notification.c_photograph_album_uid}?u=${notification.c_photograph_uid}`"
                >comment</RouterLink
              >
              on a photograph in
            </template>
            <template v-else>
              a
              <RouterLink
                class="
                  lg:hover:border-orange-500
                  text-orange-500
                  border-b border-transparent
                  transition
                  duration-150
                  ease-in-out
                "
                :to="`/album/${notification.c_photograph_album_uid}?u=${notification.c_photograph_uid}`"
                >photograph
              </RouterLink>
              in
            </template>
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/album/${notification.c_photograph_album_uid}`"
              >{{ notification.c_photograph_album_name }}
            </RouterLink>
          </template>

          <!-- . Comment/Reply Comment on video -->
          <template v-if="notification.c_video_uid && notification.c_video_circuit">
            <template v-if="notification.c_reply_to_uid"
              ><RouterLink
                class="
                  lg:hover:border-orange-500
                  text-orange-500
                  border-b border-transparent
                  transition
                  duration-150
                  ease-in-out
                "
                :to="`/videos?u=${notification.c_video_uid}`"
                >comment</RouterLink
              >
              on a video at
            </template>
            <template v-else
              >a
              <RouterLink
                class="
                  lg:hover:border-orange-500
                  text-orange-500
                  border-b border-transparent
                  transition
                  duration-150
                  ease-in-out
                "
                :to="`/event/${notification.c_video_event_slug}?u=${notification.c_video_uid}`"
                >video</RouterLink
              >
              from
            </template>
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/event/${notification.c_video_event_slug}`"
              >{{ notification.c_video_circuit
              }}<template v-if="notification.c_video_date">
                (<AtomFormattedDate :date="notification.c_video_date" format="short" />)</template
              >
            </RouterLink>
          </template>

          <!-- . Comment: Comment on attended event -->
          <template v-if="notification.c_event_slug && notification.a_e_slug">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/event/${notification.c_event_slug}`"
              >{{ notification.c_event_circuit_name }}
              <template v-if="notification.c_event_date"
                >(<AtomFormattedDate :date="notification.c_event_date" format="short" />)</template
              >
            </RouterLink>
          </template>

          <!-- . Reply: Comment on event -->
          <template v-if="notification.c_event_slug && notification.c_event_date && !notification.a_e_slug">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/event/${notification.c_event_slug}#comments`"
              >comment</RouterLink
            >
            on
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/event/${notification.c_event_slug}`"
              >{{ notification.c_event_circuit_name }}
              <template v-if="notification.c_event_date"
                >(<AtomFormattedDate :date="notification.c_event_date" format="short" />)</template
              >
            </RouterLink>
          </template>

          <!-- . Reply: Comment on circuit -->
          <template v-if="notification.c_circuit_name && notification.c_circuit_slug">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/circuit/${notification.c_circuit_slug}#comments`"
              >comment
            </RouterLink>
            on
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/circuit/${notification.c_circuit_slug}`"
              >{{ notification.c_circuit_name }}
            </RouterLink>
          </template>

          <!-- . Reply: Comment on organiser -->
          <template v-if="notification.c_organiser_name && notification.c_organiser_slug">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/organiser/${notification.c_organiser_slug}#comments`"
              >comment
            </RouterLink>
            on
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/organiser/${notification.c_organiser_slug}`"
              >{{ notification.c_organiser_name }}
            </RouterLink>
          </template>

          <!-- . Reply: Comment on album -->
          <template v-if="notification.c_album_name && notification.c_album_uid">
            <template v-if="notification.c_reply_to_uid"
              ><RouterLink
                class="
                  lg:hover:border-orange-500
                  text-orange-500
                  border-b border-transparent
                  transition
                  duration-150
                  ease-in-out
                "
                :to="`/album/${notification.c_album_uid}#comments`"
                >comment
              </RouterLink>
              on
            </template>
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="`/album/${notification.c_album_uid}`"
              >{{ notification.c_album_name }}
            </RouterLink>
          </template>
        </div>
        <p class="w-full text-sm">{{ date }}</p>
      </div>
    </div>
  </li>
</template>

<script>
  import * as timeago from 'timeago.js'

  export default {
    props: {
      notification: Object
    },
    computed: {
      date() {
        return timeago.format(this.notification.created_at)
      },
      attending() {
        const today = new Date()

        return today > Date.parse(this.notification.a_e_date) ? ' went to ' : ' is going to '
      },
      hasUserPhoto() {
        return this.notification.user_cover_photo
      },
      userPhoto() {
        const n = this.notification

        return `${process.env.VUE_APP_IMGIX}/users/${n.user_uid}/${n.user_cover_photo}?w=64&h=64&fit=crop`
      }
    }
  }
</script>
