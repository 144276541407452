<template>
  <div class="items-center justify-center text-black">
    <RouterLink to="/">
      <AtomBrand class="mx-auto w-52" />
    </RouterLink>
    <h2 class="mt-6 text-center text-2xl font-bold" :class="classes">
      {{ title }}
    </h2>
  </div>

  <p v-if="subTitle" class="mt-4 mx-auto max-w-md text-center text-black text-lg md:px-4" v-html="subTitle"></p>
</template>

<script>
  export default {
    props: {
      title: String,
      subTitle: String,
      fill: String,
      classes: Array
    }
  }
</script>
