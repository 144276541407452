<template>
  <div>
    <div class="flex-shrink-0">
      <RouterLink :to="url" class="block">
        <img class="w-full h-48 object-cover" :src="image" alt="" />
      </RouterLink>
    </div>
    <div class="flex flex-1 flex-col justify-between p-6 bg-white">
      <div class="flex-1">
        <p class="text-blue-600 text-sm font-medium leading-5">
          <RouterLink to="/news/tags/" class="hover:underline">
            {{ type }}
          </RouterLink>
        </p>
        <RouterLink :to="url" class="block">
          <h3 class="text-gray-900 mt-2 text-xl font-semibold leading-7">
            {{ title }}
          </h3>
          <p class="text-gray-500 mt-3 text-base leading-6">
            {{ excerpt }}
          </p>
        </RouterLink>
      </div>
      <div class="flex items-center mt-6">
        <div class="flex-shrink-0">
          <a href="#">
            <img class="w-10 h-10 rounded-full" src="http://placehold.it/40" alt="" />
          </a>
        </div>
        <div class="ml-3">
          <p class="text-gray-900 text-sm font-medium leading-5">
            <a href="/news/authors/" class="hover:underline">
              {{ author }}
            </a>
          </p>
          <div class="text-gray-500 flex text-sm leading-5">
            <time>
              <AtomFormattedDate :date="date"></AtomFormattedDate>
            </time>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue'

  export default {
    props: {
      id: String,
      slug: String,
      type: String,
      title: String,
      image: String,
      content: String,
      author: String,
      date: String,
      length: Number
    },
    setup(props) {
      const excerpt = computed(() => {
        let excerpt = props.content.replace(/(<([^>]+)>)/gi, '')
        excerpt = excerpt.substring(0, 100) + '…'

        return excerpt
      })
      const url = computed(() => `/post/${props.slug}`)

      return {
        excerpt,
        url
      }
    }
  }
</script>
