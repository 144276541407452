<template>
  <header class="pt-4 px-4 py-4 lg:px-8 lg:py-8">
    <div class="grid gap-4 grid-cols-4 items-start mx-auto max-w-screen-2xl lg:gap-8 lg:grid-cols-12">
      <h1
        class="
          flex
          col-span-4
          items-center
          text-black text-3xl
          font-bold
          tracking-tight
          leading-tight
          md:text-4xl
          lg:col-span-8
          lg:text-5xl
          lg:leading-tight
          xl:text-6xl
        "
      >
        <template v-if="!isPageLoading">
          {{ title }}
          <template v-if="subTitle"><br />{{ subTitle }}</template>
        </template>
        <template v-else>Loading…</template>
      </h1>
      <slot v-if="!isPageLoading && hasAsideSlot" name="aside"></slot>
    </div>
    <div v-if="hasActionsSlot" class="mx-auto max-w-screen-2xl md:flex md:items-center md:justify-between">
      <slot name="actions"></slot>
    </div>
  </header>
</template>

<script>
  export default {
    props: {
      classes: String,
      content: String,
      isPageLoading: Boolean,
      subTitle: String,
      title: String
    },
    computed: {
      hasAsideSlot() {
        return !!this.$slots.aside
      },
      hasActionsSlot() {
        return !!this.$slots.actions
      }
    }
  }
</script>
